import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
    ({ theme }) => css`
        display: inline-flex;
        flex-direction: column;
        position: relative;

        @media ${theme.breakpoints.lg.min} {
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
        }

        @media ${theme.breakpoints.vl.min} {
            top: -2rem;
            float: right;
        }
    `,
);
