import React from 'react';
import { Content, Title, Footer, Wrapper, Header, Action } from './section.styled';
import { GapSizeType } from '../../../theme/type/gap-type';

interface SectionPropsInterface {
    children: React.ReactNode;
    title?: React.ReactNode;
    action?: React.ReactNode;
    footer?: React.ReactNode;
    marginTop?: GapSizeType;
    marginBottom?: GapSizeType;
}

export const Section: React.FC<SectionPropsInterface> = ({
    title,
    children,
    footer,
    action,
    marginTop,
    marginBottom,
}) => {
    return (
        <Wrapper marginTop={marginTop} marginBottom={marginBottom}>
            <Header>
                {title && <Title>{title}</Title>}
                {action && <Action>{action}</Action>}
            </Header>

            <Content>{children}</Content>

            {footer && <Footer>{footer}</Footer>}
        </Wrapper>
    );
};
