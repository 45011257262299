import styled, { css, DefaultTheme } from 'styled-components';
import { BreakpointSizeType } from '../../../../theme/type/breakpoints-type';
import { getOpacity } from '../../../../theme/utils/get-rgb';
import { ButtonResponsiveSizeType, ButtonSizeType } from '../../../../theme/type/button-type';

const getCssSkeletonButtonSize = (sizeKey: ButtonSizeType, theme: DefaultTheme) => `
    padding: ${theme.button.size[sizeKey].padding};
`;

const getResponsiveSkeletonButtonSize = (size: ButtonResponsiveSizeType, theme: DefaultTheme) => {
    return Object.entries(size).map((item, index) => {
        const breakpointKey = item[0] as BreakpointSizeType;
        const iconSizeKey = item[1] as ButtonSizeType;

        if (index === 0) {
            return `
                @media screen and (min-width: 0px) {
                    ${getCssSkeletonButtonSize(iconSizeKey, theme)}
                }
            `;
        }

        return `       
            @media ${theme.breakpoints[breakpointKey].min} {
                ${getCssSkeletonButtonSize(iconSizeKey, theme)}
            }
        `;
    });
};

const getCssSkeletonButtonLabelSize = (sizeKey: ButtonSizeType, theme: DefaultTheme) => `
    font-size: ${theme.button.size[sizeKey].fontSize};
    line-height: ${theme.button.size[sizeKey].lineHeight};
    padding: ${theme.button.size[sizeKey].labelPadding};
    height: ${theme.button.size[sizeKey].icon.wrapper};
`;

const getResponsiveSkeletonButtonLabelSize = (size: ButtonResponsiveSizeType, theme: DefaultTheme) => {
    return Object.entries(size).map((item, index) => {
        const breakpointKey = item[0] as BreakpointSizeType;
        const iconSizeKey = item[1] as ButtonSizeType;

        if (index === 0) {
            return `
                @media screen and (min-width: 0px) {
                    ${getCssSkeletonButtonLabelSize(iconSizeKey, theme)}
                }
            `;
        }

        return `       
            @media ${theme.breakpoints[breakpointKey].min} {
                ${getCssSkeletonButtonLabelSize(iconSizeKey, theme)}
            }
        `;
    });
};
export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['size'].includes(prop),
})<{
    size: ButtonSizeType | ButtonResponsiveSizeType | undefined;
}>`
    ${({ theme, size }) => css`
        display: inline-flex;
        background-color: ${getOpacity(theme.palette.color.default.dark, 0.3)};
        border-radius: 30rem;
        overflow: hidden;
        position: relative;
        border: 0.1rem solid transparent;

        &:after {
            background: linear-gradient(90deg, transparent, ${theme.palette.color.default.opacity.high}, transparent);
            content: '';
            position: absolute;
            transform: translateX(-100%);
            inset: 0;
            animation: 1.5s linear 0.5s infinite normal none running wave;
        }

        @keyframes wave {
            0% {
                transform: translateX(-100%);
            }
            50% {
                transform: translateX(0%);
            }
            100% {
                transform: translateX(100%);
            }
        }

        ${size &&
        typeof size === 'string' &&
        css`
            ${getCssSkeletonButtonSize(size, theme)};
        `}

        ${size &&
        typeof size === 'object' &&
        css`
            ${getResponsiveSkeletonButtonSize(size, theme)};
        `}
    `}
`;

export const Label = styled.div.withConfig({
    shouldForwardProp: (prop) => !['size'].includes(prop),
})<{
    size: ButtonSizeType | ButtonResponsiveSizeType | undefined;
}>`
    ${({ theme, size }) => css`
        display: inline-flex;
        align-items: center;
        font-weight: 600;

        ${size &&
        typeof size === 'string' &&
        css`
            ${getCssSkeletonButtonLabelSize(size, theme)};
        `}

        ${size &&
        typeof size === 'object' &&
        css`
            ${getResponsiveSkeletonButtonLabelSize(size, theme)};
        `}
    `}
`;
