import React, { useEffect, useRef, useState } from 'react';
import { FontWeightEnum, getAmount, TextVariantColorEnum } from '@app/common';
import { Locked, Wrapper } from './value-percents.styled';
import { getColor } from '../../tables/utils/get-color';
import { getFakeAmount } from '../../../../utils/get-fake-amount';
import { NonVisible } from '../amount/value-amount.styled';
import { useRootStore } from '../../../../providers/root-store-provider';

type ValuePercentsCommonProps = {
    mode?: 'styled' | 'pure';
    nullValue?: number | 'hidden';
    amount: number;
    locked?: boolean;
    fractionDigits?: number;
    inParentheses?: boolean;
};

type ValuePercentsStyledProps = {
    mode: 'styled';
    fontWeight?: FontWeightEnum;
    color?: TextVariantColorEnum;
};

type ValuePercentsPureProps = {
    mode?: 'pure';
};

type ValuePercentsProps = ValuePercentsCommonProps & (ValuePercentsStyledProps | ValuePercentsPureProps);

export const ValuePercents: React.FC<ValuePercentsProps> = (props) => {
    const lockedRef = useRef<HTMLDivElement>(null);
    const rootStore = useRootStore();
    const [blur, setBlur] = useState<number>(0.3);

    const { amount, locked = false, fractionDigits = 2, inParentheses = false, nullValue } = props;

    const mode = props.mode ?? 'styled';

    const fakeAmount = getFakeAmount(amount);
    const valueBefore = getAmount(locked ? fakeAmount : amount, fractionDigits, '%', nullValue);
    const value = inParentheses ? `(${valueBefore})` : valueBefore;

    useEffect(() => {
        if (lockedRef.current) {
            const fontSizeValue = parseFloat(window.getComputedStyle(lockedRef.current).fontSize);
            const blurSize = fontSizeValue / 50;
            const roundedBlurSize = parseFloat(blurSize.toFixed(2));
            setBlur(roundedBlurSize);
        }
    }, []);

    const isHidden = !rootStore.cookieService.hasJwtToken();

    if (isHidden) {
        return <NonVisible>*** %</NonVisible>;
    }

    if ((amount === 0 || amount === -100) && nullValue === 'hidden') {
        return null;
    }

    if (mode === 'styled') {
        const { color, fontWeight = FontWeightEnum.Medium } = props as ValuePercentsStyledProps;

        return (
            <Wrapper variant={color || getColor(amount)} fontWeight={fontWeight}>
                <Locked ref={lockedRef} locked={locked} blur={blur}>
                    {value}
                </Locked>
            </Wrapper>
        );
    }

    return locked ? (
        <Locked ref={lockedRef} blur={blur} locked>
            {value}
        </Locked>
    ) : (
        <>{value}</>
    );
};
