import React from 'react';
import { Wrapper } from './inline-legend.styled';
import { InlineLegendItem } from './item/inline-legend-item';
import { TabEnum } from '../../../../../../models/enums/tab-enum';
import { AreaChartMobxDto } from '../../../../../../models/mobx/dtos/statistic/area-chart-mobx-dto';

export interface InlineLegendProps {
    data: AreaChartMobxDto[];
    tab: TabEnum;
    onChange: (id: string) => void;
    filter: Map<string, boolean>;
}

export const InlineLegend: React.FC<InlineLegendProps> = ({ data, onChange, filter }: InlineLegendProps) => {
    return (
        <Wrapper>
            {data.map((item, index) => (
                <InlineLegendItem
                    key={index}
                    label={item.id}
                    color={item.color}
                    onChange={() => onChange(item.id)}
                    checked={filter.get(item.id)}
                />
            ))}
        </Wrapper>
    );
};
