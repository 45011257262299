import styled, { css } from 'styled-components';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<{
    isActive?: boolean;
}>`
    ${({ theme, isActive }) => css`
        cursor: pointer;

        i {
            color: ${theme.palette.common.textMuted};
            width: 1.2rem;
            min-width: 1.2rem;
            transition: color ${theme.base.transition};
        }

        &:hover {
            i {
                color: ${theme.palette.common.text};
            }
        }

        ${isActive &&
        css`
            i {
                color: ${theme.palette.color.secondary.main};
            }
        `}
    `}
`;
