import React, { useEffect, useRef, useState } from 'react';
import { FontWeightEnum, TextVariantColorEnum, getAmount } from '@app/common';
import { Locked, Wrapper } from './value-quantity.styled';
import { getFakeAmount } from '../../../../utils/get-fake-amount';
import { formatBigNumber } from '../utils/format-big-number';

type ValueQuantityCommonProps = {
    mode?: 'styled' | 'pure';
    amount: number;
    suffix?: string;
    locked?: boolean;
    fractionDigits?: number;
    inParentheses?: boolean;
    bigNumber?: boolean;
};

type ValueQuantityStyledProps = {
    mode: 'styled';
    fontWeight?: FontWeightEnum;
    color?: TextVariantColorEnum;
};

type ValueQuantityPureProps = {
    mode?: 'pure';
};

type ValueQuantity = ValueQuantityCommonProps & (ValueQuantityStyledProps | ValueQuantityPureProps);

const getMinValue = (fractionDigits: number) => {
    return 10 ** -fractionDigits;
};

export const ValueQuantity: React.FC<ValueQuantity> = (props) => {
    const lockedRef = useRef<HTMLDivElement>(null);
    const [blur, setBlur] = useState<number>(0.3);

    const { amount, suffix, locked = false, fractionDigits = 3, inParentheses = false, bigNumber = false } = props;

    const mode = props.mode ?? 'styled';

    const fakeAmount = getFakeAmount(amount);

    let valueBefore: string;
    if (bigNumber) {
        valueBefore = formatBigNumber(locked ? fakeAmount : amount, fractionDigits, suffix || '');
    } else if (amount > 0 && amount < getMinValue(fractionDigits)) {
        valueBefore = `<${getAmount(
            locked ? getFakeAmount(getMinValue(fractionDigits)) : getMinValue(fractionDigits),
            fractionDigits,
            suffix || '',
        )}`;
    } else {
        valueBefore = getAmount(locked ? fakeAmount : amount, fractionDigits, suffix || '');
    }

    const value = inParentheses ? `(${valueBefore})` : valueBefore;

    useEffect(() => {
        if (lockedRef.current) {
            const fontSizeValue = parseFloat(window.getComputedStyle(lockedRef.current).fontSize);
            const blurSize = fontSizeValue / 50;
            const roundedBlurSize = parseFloat(blurSize.toFixed(2));
            setBlur(roundedBlurSize);
        }
    }, []);

    if (mode === 'styled') {
        const { color = TextVariantColorEnum.Default, fontWeight = FontWeightEnum.Medium } =
            props as ValueQuantityStyledProps;

        return (
            <Wrapper variant={color} fontWeight={fontWeight}>
                <Locked ref={lockedRef} locked={locked} blur={blur}>
                    {value}
                </Locked>
            </Wrapper>
        );
    }

    return locked ? (
        <Locked ref={lockedRef} blur={blur} locked>
            {value}
        </Locked>
    ) : (
        <>{value}</>
    );
};
