import styled, { css } from 'styled-components';
import { FontWeightEnum, TextVariantColorEnum } from '@app/common';

export const Wrapper = styled.span.withConfig({
    shouldForwardProp: (prop) => !['variant', 'fontWeight'].includes(prop),
})<{
    variant?: TextVariantColorEnum;
    fontWeight: FontWeightEnum;
}>`
    ${({ theme, variant, fontWeight }) => css`
        display: inline-block;
        white-space: nowrap;
        font-weight: ${fontWeight};

        ${variant &&
        css`
            color: ${theme.text.variant[variant].text};
        `}
    `}
`;

export const Locked = styled.span.withConfig({
    shouldForwardProp: (prop) => !['locked', 'blur'].includes(prop),
})<{
    locked: boolean;
    blur: number;
}>`
    ${({ locked, blur }) => css`
        ${locked &&
        css`
            filter: blur(${blur}rem);
        `}
    `}
`;
