import React from 'react';
import type { Swiper as SwiperType } from 'swiper';
import { SlideOption, Wrapper } from './slideshow.styled';

interface SlideshowPaginationPropsInterface {
    instance: SwiperType | undefined;
    selectedIndex: number;
    variant: 'primary' | 'positive';
    dotsCount: number;
}

export const SlideshowPagination: React.FC<SlideshowPaginationPropsInterface> = ({
    instance,
    selectedIndex,
    dotsCount,
    variant,
}) => {
    return instance ? (
        <Wrapper>
            {Array.from({ length: dotsCount }).map((_, index: number) => (
                <SlideOption
                    isActive={index === selectedIndex}
                    key={index}
                    onClick={() => instance.slideTo(index)}
                    variant={variant}
                />
            ))}
        </Wrapper>
    ) : null;
};
