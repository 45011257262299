import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        overflow: auto;
        white-space: nowrap;
        position: sticky;
        top: 120px;

        ::-webkit-scrollbar {
            display: none;
        }

        > * {
            margin-left: ${theme.gap.xsmall};

            &:first-child {
                margin-left: unset;
            }
        }
    `}
`;
