import React from 'react';
import { Wrapper, Inner, Item } from './tabs-menu.styled';
import { GapSizeType } from '../../../../theme/type/gap-type';

type TabsMenuItemsInterface<T> = {
    label: string;
    value: T;
    badge?: number | string | null;
};

interface TabsMenuPropsInterface<T> {
    items: Array<TabsMenuItemsInterface<T>>;
    variant?: 'default' | 'cards';
    value: T;
    marginBottom?: GapSizeType;
    disableGradient?: boolean;
    onChange: ((newValue: T) => Promise<void>) | ((newValue: T) => void);
}

export const TabsMenu = <T,>({
    items,
    value,
    onChange,
    variant = 'default',
    marginBottom = 'vlarge',
    disableGradient,
}: TabsMenuPropsInterface<T>) => {
    return (
        <Wrapper variant={variant} marginBottom={marginBottom}>
            <Inner variant={variant}>
                {items.map((item, index) => (
                    <Item
                        key={index}
                        variant={variant}
                        onClick={() => onChange(item.value)}
                        selected={item.value === value}
                        disableGradient={disableGradient}
                    >
                        {item.label}
                    </Item>
                ))}
            </Inner>
        </Wrapper>
    );
};
