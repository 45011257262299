import styled, { css } from 'styled-components';
import { GapSizeType } from '../../../theme/type/gap-type';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['marginTop', 'marginBottom'].includes(prop),
})<{
    marginTop?: GapSizeType;
    marginBottom?: GapSizeType;
}>`
    ${({ theme, marginTop, marginBottom }) => css`
        margin-top: ${marginTop ? theme.gap[marginTop] : 'unset'};
        margin-bottom: ${marginBottom ? theme.gap[marginBottom] : 'unset'};

        & + & {
            margin-top: ${theme.gap.xlarge};
        }
    `}
`;

export const Title = styled.div`
    ${({ theme }) => css`
        font-size: ${theme.headline.size.h3.fontSize};
        line-height: ${theme.headline.size.h3.lineHeight};
        font-weight: 700;
        color: ${theme.palette.color.secondary.main};
    `}
`;

export const Content = styled.div``;

export const Footer = styled.div`
    ${({ theme }) => css`
        margin-top: ${theme.gap.medium};
        display: flex;
        justify-content: right;
    `}
`;

export const Header = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        margin-bottom: ${theme.gap.medium};

        @media ${theme.breakpoints.lg.min} {
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
        }
    `}
`;

export const Action = styled.div`
    ${({ theme }) => css`
        margin-top: ${theme.gap.medium};

        @media ${theme.breakpoints.lg.min} {
            margin-top: unset;
        }
    `}
`;
