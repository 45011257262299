import React from 'react';
import { Label, Wrapper } from './skeleton-button.styled';
import { ButtonResponsiveSizeType, ButtonSizeType } from '../../../../theme/type/button-type';

type SkeletonButtonType = {
    size: ButtonSizeType | ButtonResponsiveSizeType | undefined;
    children: React.ReactNode;
};

const SkeletonButton: React.FC<SkeletonButtonType> = ({ size, children }: SkeletonButtonType) => {
    return (
        <Wrapper size={size}>
            <Label size={size}>{children}</Label>
        </Wrapper>
    );
};

export default SkeletonButton;
