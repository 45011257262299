import styled, { css } from 'styled-components';
import { GapSizeType } from '../../../../theme/type/gap-type';

type ItemProps = {
    selected?: boolean;
    variant: 'default' | 'cards';
    disableGradient?: boolean;
};

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['variant', 'marginBottom'].includes(prop),
})<{
    variant: 'default' | 'cards';
    marginBottom: GapSizeType;
}>(
    ({ theme, variant, marginBottom }) => css`
        display: flex;
        align-items: center;
        overflow-x: auto;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        ${variant === 'cards' &&
        css`
            display: flex;
            margin-bottom: ${theme.gap[marginBottom]};
            position: relative;
            overflow-x: auto;

            --container-width: min(100%, ${theme.base.containerWidth});
            --side-space: calc((100vw - var(--container-width)) / 2);

            &:before {
                content: '';
                height: 0.1rem;
                right: 100%;
                position: absolute;
                bottom: 0;
                width: var(--side-space);
                background-color: ${theme.palette.common.border};
            }

            &:after {
                content: '';
                height: 0.1rem;
                left: 100%;
                position: absolute;
                bottom: 0;
                width: var(--side-space);
                background-color: ${theme.palette.common.border};
            }
        `}
    `,
);

export const Inner = styled.div.withConfig({
    shouldForwardProp: (prop) => !['variant'].includes(prop),
})<{
    variant: 'default' | 'cards';
}>(
    ({ theme, variant }) => css`
        display: inline-flex;
        align-items: center;
        width: 100%;

        ${variant === 'cards' &&
        css`
            border-bottom: 0.1rem solid ${theme.palette.common.border};
        `}
    `,
);

export const Item = styled.div.withConfig({
    shouldForwardProp: (prop) => !['selected', 'variant', 'disableGradient'].includes(prop),
})<ItemProps>(
    ({ theme, selected, variant, disableGradient }) => css`
        color: ${theme.palette.common.textMuted};
        position: relative;
        font-weight: 500;
        cursor: pointer;
        transition: color ${theme.base.transition};
        text-align: center;
        white-space: nowrap;

        @media ${theme.breakpoints.sm.max} {
            font-size: ${theme.text.size.xsmall.fontSize};
            line-height: ${theme.text.size.xsmall.lineHeight};
        }

        &:hover {
            color: ${theme.palette.common.text};
        }

        ${selected &&
        css`
            color: ${theme.palette.common.text};

            &:before {
                content: '';
                height: 0.1rem;
                background: ${theme.palette.background.body};
                position: absolute;
                bottom: -0.1rem;
                left: 0;
                right: 0;
                z-index: 100;
            }

            &:after {
                content: '';
                width: 1.6rem;
                height: 0.2rem;
                background: ${theme.palette.gradient.primary.main};
                position: absolute;
                bottom: -0.2rem;
                left: 50%;
                transform: translate(-50%, 0.5rem);
            }
        `}

        ${variant === 'default' &&
        css`
            margin-left: ${theme.gap.small};

            &:first-child {
                margin-left: unset;
            }
        `}

        ${variant === 'cards' &&
        css`
            padding: ${theme.gap.xsmall} ${theme.gap.xsmall};
            border-radius: ${theme.radius.large} ${theme.radius.large} 0 0;
            border: 0.1rem solid ${theme.palette.background.body};
            border-bottom-color: ${theme.palette.common.border};
            margin-bottom: -0.1rem;

            @media ${theme.breakpoints.sm.min} {
                padding: ${theme.gap.vsmall} ${theme.gap.medium};
            }

            ${selected &&
            css`
                border-color: ${theme.palette.common.border};
                border-bottom-color: ${theme.palette.background.body};
                font-weight: 700;

                ${!disableGradient &&
                css`
                    background: linear-gradient(
                        0deg,
                        ${theme.palette.background.body} 0%,
                        ${theme.palette.color.gray.opacity.high} 100%
                    );
                `}

                &:after {
                    bottom: 0;
                    transform: translate(-50%, 0);
                }
            `}
        `}
    `,
);

export const BadgeWrapper = styled.div`
    ${({ theme }) => css`
        position: absolute;
        top: -1rem;
        left: 50%;
        transform: translate(-50%, 0);
        background-color: ${theme.palette.color.white.main};
        border-radius: 20rem;
    `}
`;
