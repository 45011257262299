import styled, { css } from 'styled-components';

export const Wrapper = styled.label(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        @media ${theme.breakpoints.lg.max} {
            margin-top: 1.6rem;
        }

        @media ${theme.breakpoints.lg.min} {
            margin-left: 2.4rem;
        }

        &:first-child {
            margin-left: unset;
        }

        input {
            position: absolute;
            left: -9999px;
        }

        input:checked + div i {
            opacity: 1;
            color: ${theme.palette.common.textInverse};
        }
    `,
);

export const Name = styled.div(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        color: ${theme.palette.common.textMuted};
        font-weight: 500;
    `,
);

export const Symbol = styled.div.withConfig({
    shouldForwardProp: (prop) => !['color'].includes(prop),
})<{
    color: string;
}>(
    ({ theme, color }) => css`
        width: 2rem;
        height: 2rem;
        border-radius: 100%;
        background-color: ${color};
        margin-right: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            width: 1.2rem;
            height: 1.2rem;
            min-width: 1.2rem;
            color: ${theme.palette.color.white.main};
            opacity: 0;
        }
    `,
);
