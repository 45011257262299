import styled from 'styled-components';

export const Wrapper = styled.div`
    .swiper-container {
        overflow: visible !important;
    }

    .swiper-wrapper {
        overflow: visible;
    }

    .swiper-slide {
        height: unset;
    }
`;
