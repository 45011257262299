import React from 'react';
import { Wrapper } from './sort-button.styled';
import { IconArrowsUpDown } from '../icons/arrows-up-down';
import { IconArrowUp } from '../icons/arrow-up';
import { IconArrowDown } from '../icons/arrow-down';
import { OrderEnum } from '../../../defs/api';

interface SortButtonProps {
    order: OrderEnum | null | undefined | '';
    onSortChange: (newOrder: OrderEnum | null) => void;
}

export const SortButton: React.FC<SortButtonProps> = ({ onSortChange, order }: SortButtonProps) => {
    if (!order) {
        return (
            <Wrapper onClick={() => onSortChange(OrderEnum.Desc)}>
                <IconArrowsUpDown />
            </Wrapper>
        );
    }
    return (
        <>
            {order === OrderEnum.Asc && (
                <Wrapper isActive onClick={() => onSortChange(null)}>
                    <IconArrowUp />
                </Wrapper>
            )}
            {order === OrderEnum.Desc && (
                <Wrapper isActive onClick={() => onSortChange(OrderEnum.Asc)}>
                    <IconArrowDown />
                </Wrapper>
            )}
        </>
    );
};
