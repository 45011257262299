import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconZoom: React.FC = () => (
    <Icon viewBox="0 0 64 64" fill="none">
        <path
            d="m62.42666666666666 54.906666666666666 -11.226666666666667 -11.226666666666667a27.946666666666665 27.946666666666665 0 1 0 -7.546666666666667 7.546666666666667l11.253333333333332 11.226666666666667a5.333333333333333 5.333333333333333 0 0 0 7.52 0 5.333333333333333 5.333333333333333 0 0 0 0 -7.546666666666667ZM28 5.333333333333333A22.666666666666664 22.666666666666664 0 1 1 5.333333333333333 28 22.69333333333333 22.69333333333333 0 0 1 28 5.333333333333333Z"
            fill="currentColor"
            strokeWidth="1"
        />
        <path
            d="M18.666666666666664 30.666666666666664h6.666666666666666V37.33333333333333a2.6666666666666665 2.6666666666666665 0 0 0 5.333333333333333 0v-6.666666666666666H37.33333333333333a2.6666666666666665 2.6666666666666665 0 0 0 0 -5.333333333333333h-6.666666666666666V18.666666666666664a2.6666666666666665 2.6666666666666665 0 0 0 -5.333333333333333 0v6.666666666666666H18.666666666666664a2.6666666666666665 2.6666666666666665 0 0 0 0 5.333333333333333Z"
            fill="currentColor"
            strokeWidth="1"
        />
    </Icon>
);
