import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconArrowsUpDown: React.FC = () => (
    <Icon width="11" height="16" viewBox="0 0 11 16" fill="none">
        <path
            d="M1 10L5.5 14.5L10 10"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M1 5.5L5.5 1L10 5.5"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
);
