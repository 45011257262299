import styled, { css } from 'styled-components';
import { getOpacity } from '../../../theme/utils/get-rgb';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        margin: ${theme.gap.medium} ${theme.gap.medium} 0;
        gap: ${theme.gap.small};
        align-items: center;
        justify-content: center;

        &:empty {
            display: none;
        }
    `};
`;

export const SlideOption = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isActive', 'variant'].includes(prop),
})<{
    isActive: boolean;
    variant: 'primary' | 'positive';
}>`
    ${({ theme, isActive, variant }) => css`
        width: 1.4rem;
        height: 1.4rem;
        border-radius: ${theme.radius.circle};
        background: ${getOpacity(theme.palette.color[variant].dark, 0.5)};

        ${isActive &&
        css`
            background: ${theme.palette.color[variant].dark};

            &:after {
                display: block;
                position: relative;
                content: '';
                width: 2.4rem;
                height: 2.4rem;
                bottom: ${theme.gap.xxsmall};
                right: ${theme.gap.xxsmall};
                border: 0.1rem solid ${theme.palette.color[variant].dark};
                border-radius: ${theme.radius.circle};
            }
        `}
    `};
`;
