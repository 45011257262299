import React from 'react';
import { Wrapper } from './tabs-pane.styled';

interface TabsPanePropsInterface {
    children: React.ReactNode;
    value: string;
    activeTab: string;
}

export const TabsPanel: React.FC<TabsPanePropsInterface> = ({ children, value, activeTab }) => {
    if (value === activeTab) {
        return <Wrapper>{children}</Wrapper>;
    }

    return null;
};
