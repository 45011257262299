import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperType } from 'swiper';
import { SlideshowPagination } from '../slideshow/slideshowPagination';
import { Wrapper } from './swiper.styled';

interface SlideshowPropsInterface {
    children: React.ReactNode[];
    variant?: 'primary' | 'positive';
}
export const Slideshow: React.FC<SlideshowPropsInterface> = ({ children, variant = 'primary' }) => {
    const [swiperInstance, setSwiperInstance] = useState<SwiperType>();
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [dotsCount, setDotsCount] = useState<number>(0);

    const updateDotsCount = (swiper: SwiperType) => {
        swiper.slideTo(0);
        const viewSlides = swiper.params.slidesPerView as number;
        const totalSlides = swiper.slides.length;
        let newDotsCount;

        if (viewSlides >= totalSlides) {
            newDotsCount = 0;
        } else {
            newDotsCount = totalSlides - viewSlides + 1;
        }

        setDotsCount(newDotsCount);
    };

    useEffect(() => {
        if (swiperInstance) {
            updateDotsCount(swiperInstance);
        }
    }, [swiperInstance]);

    return (
        <Wrapper>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.css" />
            <Swiper
                grabCursor={true}
                slidesPerView={1}
                spaceBetween={30}
                simulateTouch={false}
                onBeforeResize={(swiper) => {
                    setSwiperInstance(swiper);
                    updateDotsCount(swiper);
                }}
                breakpoints={{
                    850: {
                        slidesPerView: 2,
                        spaceBetween: 16,
                    },
                    1200: {
                        slidesPerView: 4,
                        cssMode: true,
                    },
                }}
                onSwiper={(swiper) => {
                    setSwiperInstance(swiper);
                    updateDotsCount(swiper);
                }}
                onSlideChange={(swiper) => {
                    setSelectedIndex(swiper.activeIndex);
                }}
            >
                {children.map((child, index) => {
                    return <SwiperSlide key={index}>{child}</SwiperSlide>;
                })}
            </Swiper>
            <SlideshowPagination
                instance={swiperInstance}
                selectedIndex={selectedIndex}
                dotsCount={dotsCount}
                variant={variant}
            />
        </Wrapper>
    );
};
