import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Wrapper, Name, Symbol } from './inline-legend-item.styled';
import { IconCheck } from '../../../../../icons/check';

export interface InlineLegendItemProps {
    label: string;
    color: string;
    onChange?: () => void;
    checked?: boolean;
}

export const InlineLegendItem: React.FC<InlineLegendItemProps> = observer(
    ({ label, color, onChange, checked = true }: InlineLegendItemProps) => {
        const { t } = useTranslation();
        return (
            <Wrapper>
                <Name>
                    <input type="checkbox" checked={checked} onChange={onChange} />
                    <Symbol color={color}>
                        <IconCheck />
                    </Symbol>
                    {t(`pages.index.chart.legend.${label}`)}
                </Name>
            </Wrapper>
        );
    },
);
