import React from 'react';
import { Wrapper } from './button-filter.styled';

export interface ButtonFilterPropsInterface {
    children?: JSX.Element | Array<JSX.Element> | string;
}

export const ButtonFilter: React.FC<ButtonFilterPropsInterface> = ({ children }) => {
    return <Wrapper>{children}</Wrapper>;
};
